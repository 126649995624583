import React from 'react'
import { Link } from "gatsby";
import './offerCard.scss'
import {formatDate, formatDevURL, setTrim } from '../utility/offerFunctions'


const OfferCard = (props) => {
    const {offer, liveActionShots, placeholder, setContentType, cpo} = props

    const handleExpand = (e) => {
        e.currentTarget.classList.toggle('open')
        e.currentTarget.nextSibling.classList.toggle('expand')
    }

    return (
        <div className="special__container">
            <div className="special__image">
                <img alt={`New ${offer.year} ${offer.make} ${offer.model} Special`}
                    title={`New ${offer.year} ${offer.make} ${offer.model} Special`}
                    className="special__image-background"
                    src={formatDevURL(offer.background_url)} />
                {(liveActionShots)
                    || <img alt={`New ${offer.year} ${offer.make} ${offer.model}`}
                        title={`New ${offer.year} ${offer.make} ${offer.model} Special`}
                        className="special__image-jellybean"
                        src={formatDevURL(offer.photo_url)} />}
            </div>
            <div className="special__info">
                {!placeholder
                    ? <h2 className="title"> {(offer.cpoOffers || cpo) ? '' : 'New'} {offer.year} {offer.make} {offer.model}</h2>
                    : <h2 className="title">New {offer.year} {offer.make} {offer.model}</h2>}
                {(!placeholder && offer.trim !== '') && <p className="sub-title">{offer.trim}</p>}
                {!placeholder
                    ? 'apr' in offer
                        ? <div className="special__info-price">
                            <div className="apr"><p>{offer.apr}%</p></div>
                            <div className="term"><p>APR FINANCING<br/>FOR {offer.term} MONTHS</p></div>
                        </div>
                    : 'payment' in offer
                        ? <div>
                            <div className="special__info-price">
                                <div className="payment"><p>${offer.payment}</p></div>
                                <div className="months"><p>LEASE PAYMENT<br/>FOR {offer.term} MONTHS</p></div>
                            </div>
                            <div className="down"><p>${offer.down} <span className="down__text">TOTAL DUE AT SIGNING</span></p></div>
                        </div>
                    : 'offMsrp' in offer
                        ? <div className="special__info-price">
                            <div className="cash"><p>${offer.offMsrp}</p></div>
                            <div className="offMsrp"><p>OFF<br/>MSRP</p></div>
                        </div>
                        : ''
                    : ''}
                <div className="special__buttons">
                    <Link className="button" to={offer.btns[3].slug}>VIEW INVENTORY</Link>
                </div>
                {!placeholder
                    && <p className="program">{offer.sales_program}</p>}
                {!placeholder
                    && <p className="expiration">Expires {formatDate(offer.expiration)}</p>}
                {(!placeholder && offer.btns[2].model !== "DIRECTIONS")
                    && <Link className="link" to={offer.btns[2].slug}>LEARN MORE</Link>}
            </div>
            <div className="special__disclaimer">
                <div onClick={handleExpand} className="special__disclaimer-dropdown">
                    <p>OFFER DETAILS</p>
                </div>
                <div className="special__disclaimer-content contract">
                    <p>{(offer.down !== undefined) && `$${offer.down} Total due at signing. Includes down payment, no security deposit required; excludes tax, title, license, and dealer fees. For well-qualified customers.`} {offer.disclaimer}</p>
                </div>
            </div>
        </div>
    )
}

export default OfferCard
